import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import horizontalScroll from 'el-table-horizontal-scroll'

import { createHead } from '@vueuse/head'

import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App);

const head = createHead()

app.use(head)
app.use(horizontalScroll);
app.use(VueReCaptcha, { siteKey: '6Lej8ogqAAAAANQo_e52_QxBuAM2sx7I11FCXwgJ' });
app.config.globalProperties.pageTitle = ''; 
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || 'Symone';
  app.config.globalProperties.pageTitle = document.title;
  if(to.meta.content) {
    let head = document.getElementByTagName('head')
    let meta = document.createElemnet('meta')
    document.querySelector('meta[name="keywords"]').setAttribute('content',to.meta.content.keywords)
    document.querySelector('meta[name="description"]').setAttribute('content',to.meta.content.description)
    meta.content = to.meta.content
    head[0].appendChild(meta)
  }
  const currentPage = to.path;
  const hasReloaded = sessionStorage.getItem(`reloaded_${currentPage}`);

  if (!hasReloaded) {
    sessionStorage.setItem(`reloaded_${currentPage}`, 'true'); 
    location.reload(true); 
  } else {
    next();
  }
});

app.use(ElementPlus);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
app.use(router).mount('#app');
