import { createRouter, createWebHistory } from 'vue-router';
import AdminSpace from '@/views/Admin.vue'; 
import AxaPassion from '@/views/AxaPassion.vue';
import Condition from '@/views/Condition.vue';
import Contact from '@/views/Contact.vue';
import EstimateResponse from '@/components/EstimateResponse.vue';
import FaqPage from '@/views/FaqPage.vue';
import FaqPageEN from '@/views/FaqPageEN.vue';
import ForgotPsw from '@/views/ForgotPsw.vue';
import FormContactSucces from '@/views/FormContactSucces.vue';
import FormQuotationSucces from '@/views/FormQuotationSucces.vue';
import Gift from '@/views/Gift.vue';
import Home from '@/views/Home.vue'; 
import HomeEN from '@/views/HomeEN.vue'; 
import HomeMobile from '@/views/HomeMobile.vue';
import HomeMobileEN from '@/views/HomeMobileEN';
import Moto from '@/views/Moto.vue'; 
import News from '@/views/News.vue';
import NewsList from '@/views/NewsList.vue';
import Login from '@/components/Login.vue';
import ResetPsw from '@/views/ResetPsw.vue';
import InvestorSpace from '@/views/InvestorSpace.vue';
import InvestorSpaceEN from '@/views/InvestorSpaceEN.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { title: 'SYMONE - Voyagez avec votre véhicule sans le conduire' }
  },
  {
    path: '/m',
    name: 'HomeMobile',
    component: HomeMobile,
    meta: { title: 'SYMONE - Voyagez avec votre véhicule sans le conduire' }
  },
  {
    path: '/en',
    name: 'HomeEN',
    component: HomeEN,
    meta: { title: 'SYMONE - Travel with your vehicle without driving it' }
  },
  {
    path: '/m/en',
    name: 'HomeMobileEN',
    component: HomeMobileEN,
    meta: { title: 'SYMONE - Travel with your vehicle without driving it' }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminSpace,
    meta: { title: 'Admin - Symone', requiresAuth: true }
  },
  {
    path: '/moto',
    name: 'Moto',
    component: Moto,
    meta: { title: 'Transport de motos longue distance - SYMONE Moto', description: "Transportez votre moto sur de longs trajets sans effort ! Avec SYMONE Moto, évitez la fatigue et profitez d'un voyage sécurisé et confortable." },
    props: true
  },
  {
    path: '/axa',
    name: 'AxaPassion',
    component: AxaPassion,
    meta: { title: 'Offre partenaire AXA Passion - SYMONE' }
  },
  {
    path: '/news',
    name: 'NewsList',
    component: NewsList,
    meta: { title: 'Dernières actualités SYMONE - Innovation & mobilité' }
  },
  {
    path: '/news/:id',
    name: 'News',
    component: News,
    meta: { title: 'Actus SYMONE' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contactez SYMONE - Infos & Réservations transport' },
    props: true
  },
  {
    path: '/faq',
    name: 'FaqPage',
    component: FaqPage,
    meta: { title: 'Questions fréquentes sur SYMONE - Tout savoir' }
  },
  {
    path: '/en/faq',
    name: 'FaqPageEN',
    component: FaqPageEN,
    meta: { title: 'FAQ about SYMONE - Everything you need to know' }
  },
  {
    path: '/condition',
    name: 'Condition',
    component: Condition,
    meta: { title: 'Conditions de vente - Symone' },
    props: true
  },
  {
    path: '/gift',
    name: 'Gift',
    component: Gift,
    meta: { title: 'Carte cadeau - Symone' },
    props: true
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Login - Symone' },
  },
  {
    path: '/form/quotation/succes',
    name: 'FormQuotationSucces',
    component: FormQuotationSucces,
    meta: { title: 'Formulaire envoyé - Symone' },
  },
  {
    path: '/form/contact/succes',
    name: 'FormContactSucces',
    component: FormContactSucces,
    meta: { title: 'Formulaire envoyé - Symone' },
  },
  {
    path: '/forgotPsw',
    name: 'ForgotPsw',
    component: ForgotPsw,
    meta: { title: 'Mot de passe oublié - Symone' },
  },
  {
    path: '/reset-password',
    name: 'ResetPsw',
    component: ResetPsw,
    meta: { title: 'Réinitialisation de mot de passe - Symone' },
  },
  {
    path: '/estimate/send/response/:id/:token',
    name: 'EstimateResponse',
    component: EstimateResponse,
    props: true
  },
  {
    path: '/investors',
    name: 'InvestorSpace',
    component: InvestorSpace,
    meta: { title: 'Investissez avec / dans SYMONE et révolutionnez la mobilité !' },
  },
  {
    path: '/en/investors',
    name: 'InvestorSpaceEN',
    component: InvestorSpaceEN,
    meta: { title: 'Investissez avec / dans SYMONE et révolutionnez la mobilité !' },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router;