<template>
  <div style="width: -webkit-fill-available">
    <div class="nav" style="position: fixed; z-index: 2">
      <a href="/en" target="_self"
        ><img
          src="../assets/images/ui/logo.png"
          style="max-width: 150px; margin: 0 20px"
      /></a>
      <div class="menu details">
        
        <a href="/en" target="_self" class="menu-web">Home</a>
        <a href="/moto" target="_blank" class="menu-web">Symone by motorcycle</a>
        <a href="/en/investors" target="_self" class="menu-web">Investor space</a>
        <a href="/news" target="_blank" class="menu-web">News</a>
        <a href="/contact" target="_blank" class="menu-web">Contact</a>
        <a href="/en/faq" target="_blank" class="menu-web">FAQ</a>
      
        <el-menu
          ellipsis
          mode="horizontal"
          background-color="white"
          text-color="black"
          active-text-color="#E6007E"
          class="menu-mobile"
        >
          <el-menu-item index="1"><a href="/en" target="_self">Home</a></el-menu-item>
          <el-menu-item index="2"><a href="/moto" target="_blank">Symone by motorcycle</a></el-menu-item>
          <el-menu-item index="3"><a href="/en/investors" target="_self">Investor Space</a></el-menu-item>
          <el-menu-item index="4"><a href="/news" target="_blank">News</a></el-menu-item>
          <el-menu-item index="5"><a href="/contact" target="_blank">Contact</a></el-menu-item>
          <el-menu-item index="6"><a href="/en/faq" target="_blank">FAQ</a></el-menu-item>
        </el-menu>
        <a href="/faq" target="_self"
          ><img
            src="../assets/images/ui/france.png"
            style="width: 25px; height: 25px"
        /></a>
        <a href="/en/faq" target="_self"
          ><img
            src="../assets/images/ui/royaume-uni.png"
            style="width: 25px; height: 25px"
        /></a>
      </div>
    </div>
    <div class="container">
      <div class="title">FAQ</div>
      <div class="w-1">
        <div class="txt">
          <el-collapse accordion>
            <el-collapse-item title="A longer journey?" name="1">
              <div
                id="elementor-tab-content-1981"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="1"
                role="region"
                aria-labelledby="elementor-tab-title-1981"
                style="display: block"
              >
                <p>
                  <span style="color: #000000"
                    ><strong
                      >Save time by travelling more slowly!&nbsp;</strong
                    ></span
                  >
                </p>
                <p>
                  A Symone journey during the day will take only a little more time than a journey by car. <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >It will be many, many hours saved and put to good use in a comfortable space where you can do something other than driving. </span
                  ><span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >&nbsp;</span
                  >
                </p>
                <p>
                  <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >For an overnight journey, you'll even save a day because you've combined sleep and travel. You'll arrive at your destination refreshed and rested. </span
                  >
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item title="What about loading time?" name="2">
              <div
                id="elementor-tab-content-1982"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="2"
                role="region"
                aria-labelledby="elementor-tab-title-1982"
                style="display: block"
              >
                <p>
                  Carried out by a professional before the toll, this operation takes around 15 minutes for all the vehicles. 
                </p>
                <p>
                  Since this is done as the vehicles arrive, 
                  <strong
                    >the loading time will be transparent for you</strong
                  >. While you're waiting for departure, you can start enjoying the many services on board.
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="Why are the vehicles on top?"
              name="3"
            >
              <div
                id="elementor-tab-content-1983"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="3"
                role="region"
                aria-labelledby="elementor-tab-title-1983"
                style="display: block"
              >
                <p>
                  There are a number of technical and regulatory constraints that force us to have this configuration, with vehicles on top (+ bottom at the rear). However, we meet the very strict safety standards imposed (including R66). The low floor, the superstructure and the location of the tanks give us a very attractive centre of gravity in this configuration.&nbsp;
                </p>
                <p>
                  This configuration is also more suitable for people with reduced mobility, and the “&nbsp;living area&nbsp;” is much better protected thanks to the reinforced structure. 
                </p>
                <p>The maximum length (regulatory) is 18.75m. </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="How much will this service cost? "
              name="4"
            >
              <div
                id="elementor-tab-content-1984"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="4"
                role="region"
                aria-labelledby="elementor-tab-title-1984"
                style="display: block"
              >
                <p>
                  <strong
                    >The price of a journey by Symone will be equivalent to the actual cost of the same journey by car!&nbsp;</strong
                  >
                </p>
                <p>
                  <b
                    >That's more freedom of thought, more free time, more comfort, more safety and more ecology!</b
                  >
                  This cost will be fully offset by savings on tolls, fuel, wear and tear on your vehicle, maintenance, mileage discounts and other costs that are often overlooked. 
                </p>
                <p>
                  <strong
                    >You book your ticket for one car, regardless of the number of passengers on board. </strong
                  >
                </p>
                <p>
                  <strong
                    >During Symone moto's first season, customers were very satisfied with the price and service. They saved money on their journey with all the advantages of the train over the motorway. With Symone autocar, it won't cost any more than taking your car. With the added comfort, safety and ecology. And that's not counting the value you give to your time (for example, 6 hours of free time saved on a Paris-Marseille journey by Symone). </strong
                  >
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="How do I book a trip? "
              name="5"
            >
              <div
                id="elementor-tab-content-1985"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="5"
                role="region"
                aria-labelledby="elementor-tab-title-1985"
                style="display: block"
              >
                <p>
                  Bookings can be made via our mobile application and our website, where all the details are detailed. 
                </p>
              </div>
            </el-collapse-item>
            <el-collapse-item
              title="And the train with a rent car? "
              name="6"
            >
              <div
                id="elementor-tab-content-1986"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="6"
                role="region"
                aria-labelledby="elementor-tab-title-1986"
                style="display: block"
              >
                <p>
                  <strong
                    >Every mode of transport has its advantages, and Symone complements the railways by bringing the advantages of the train to the motorway with your car in your luggage! </strong
                  >
                </p>
                <p>
                  Tens of thousands of people still take the motorway every day over long distances, and they all have a good reason for not taking the train, or even the autotrain when it still existed. These people need their vehicle on arrival, the ticket per passenger can quickly make the journey very expensive, the door-to-door journey time is also lengthened if you live on the outskirts of a large city, too many constraints to get to the city centre, pay for a car park or another mode of transport, etc. 
                </p>
                <p>
                  <strong
                    >All good reasons to take a motorway. All good reasons to take a Symone.&nbsp;</strong
                  >
                </p>
                <p>
                  <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    >More:</span
                  >
                </p>
                <p>
                  <a
                    style="
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                      background-color: #ffffff;
                    "
                    href="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F891004718882454%2F&amp;show_text=true&amp;width=560&amp;t=0%20width=560%20height=429%20style=border:none;overflow:hidden%20scrolling=no%20frameborder=0%20allowfullscreen=true%20allow=autoplay;%20clipboard-write;%20encrypted-media;%20picture-in-picture;%20web-share%20allowFullScreen=true/iframe"
                    ><iframe
                      style="overflow: hidden"
                      src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F891004718882454%2F&amp;show_text=true&amp;width=560&amp;t=0"
                      data-opt-src="https://www.facebook.com/plugins/video.php?height=314&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F891004718882454%2F&amp;show_text=true&amp;width=560&amp;t=0"
                      width="560"
                      height="750"
                      frameborder="0"
                      scrolling="no"
                      allowfullscreen="allowfullscreen"
                      data-opt-lazy-loaded="true"
                    ></iframe
                  ></a>
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title="How is Symone different from the autotrain? "
              name="7"
            >
              <div
                id="elementor-tab-content-1987"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="7"
                role="region"
                aria-labelledby="elementor-tab-title-1987"
                style="display: block"
              >
                <p>
                  <span
                    style="
                      color: var(--e-global-color-text);
                      font-family: var(--e-global-typography-text-font-family),
                        Sans-serif;
                      font-weight: var(--e-global-typography-text-font-weight);
                      font-size: 1rem;
                    "
                    ><strong
                      >Symone is very flexible, will already be on your route and can be deployed easily between any number of towns </strong
                    >
                    thanks to the existing dense motorway network. </span
                  >
                </p>
                <p>
                  The autotrain was no longer adapted to its time. There were too many constraints for both users and the SNCF. You had to go to the centre of Paris to load your car. You had to pay for a ticket for each passenger on another train and then wait again to collect your car. Infrastructure and logistics limited the number of departure and arrival stations. 
                </p>
                <p>More:</p>
                <p></p>
                <blockquote
                  class="wp-embedded-content"
                  data-secret="AHxir0rvIb"
                >
                  <a
                    href="https://mediarail.wordpress.com/2021/08/09/les-trains-auto-couchettes-de-jadis-quel-avenir-de-nos-jours/"
                    >The auto-sleeping trains of the past, what future do they have today&nbsp;?</a
                  >
                </blockquote>
                <iframe
                  class="wp-embedded-content"
                  sandbox="allow-scripts"
                  security="restricted"
                  style="position: absolute; clip: rect(1px, 1px, 1px, 1px)"
                  title="«&nbsp;Les trains auto-couchettes de jadis, quel avenir de nos jours&nbsp;?&nbsp;» — Mediarail.be  - Rail Europe News"
                  src="about:blank"
                  data-opt-src="https://mediarail.wordpress.com/2021/08/09/les-trains-auto-couchettes-de-jadis-quel-avenir-de-nos-jours/embed/#?secret=QB8JJhnyOy#?secret=AHxir0rvIb"
                  data-secret="AHxir0rvIb"
                  width="600"
                  height="338"
                  frameborder="0"
                  marginwidth="0"
                  marginheight="0"
                  scrolling="no"
                ></iframe>
                <p></p>
              </div>
            </el-collapse-item>

            <el-collapse-item title="What about autonomous vehicles? " name="8">
              <div
                id="elementor-tab-content-1988"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="8"
                role="region"
                aria-labelledby="elementor-tab-title-1988"
                style="display: block"
              >
                <p>
                  A great deal of progress has been made in recent years, but the first autonomous vehicles that will allow you to sleep or work in your car are not expected before 2040. A distant future, then, even if motorway driving assistance systems are tending to proliferate towards 2030. 
                </p>
                <p>
                  Especially when you consider that the average age of the French car fleet is 12 years. 
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title="Why hydrogen? Is this kind of energy ready?"
              name="9"
            >
              <div
                id="elementor-tab-content-1989"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="9"
                role="region"
                aria-labelledby="elementor-tab-title-1989"
                style="display: block"
              >
                <p>
                  Hydrogen is the energy best suited to heavy, long-distance transport like Symone. Hydrogen offers great autonomy and reduced charging time. 
                </p>
                <p>
                  So Symone and 
                  <strong
                    >green hydrogen (from electrolysis using renewable energies)</strong
                  > for shared motorway journeys, and other energies for individual cars.
                </p>
                <p>
                  Imagine small electric city cars recharging on the back of a Symone while their passengers sip their coffee inside. 
                </p>
                <p>
                  <strong
                    >An excellent example of the energy mix and multimodality. The right energy for the right use - that's the future! </strong
                  >
                </p>
                <p>
                  The first SYMONE vehicles will run on bioGNV, like the prototype. The deployment of hydrogen stations and the development of heavy vehicle engines will be mature enough by the time SYMONE transitions to decarbonized hydrogen in a second phase.
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item title='Why "Symone"? ' name="10">
              <div
                id="elementor-tab-content-19810"
                class="elementor-tab-content elementor-clearfix elementor-active"
                data-tab="10"
                role="region"
                aria-labelledby="elementor-tab-title-19810"
                style="display: block"
              >
                <p>"By car, Simone!"</p>
                <p>
                  Initially a simple joke between the founders, the name finally took on its full meaning. With an international 
                  '&nbsp;S<strong>y</strong>mone&nbsp;' touch for such an ambitious project, which is of course destined to be exported. 
                </p>
                <p>
                  Popularised by Guy Lux when he presented the TV show '&nbsp;Intervilles&nbsp;' in the 1960s, the expression is a little older ... 
                </p>
                <p>
                  If you watched the TV program “Intervilles” presented by the tandem Guy Lux - Léon Zitrone, you must surely have heard these words: 'En voiture Simone, c'est moi qui conduis, c'est toi qui honkonnes!' (by car, Simone, it's me who drives, it's you who honks!). A wink to Simone Garnier, co-host of the show? Yes, but the expression itself refers to another woman: Simone-Louise de Pinet de Borde des Forest. 
                </p>
                <p>
                  Simone-Louise de Pinet de Borde des Forest was one of the first female pilots in France. She obtained her driving licence in 1929 (rare for a woman at the time) and began racing cars the following year, in 1930. For 27 years, she took the start of numerous races and is said to have never had an accident - a fine thumbing of the nose at clichés about women drivers. &nbsp;
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title='"How many tonnes of displaced persons for a few passengers? In "ecology", there is the question of CO2 of course, but also that of energy efficiency."'
              name="11"
            >
              <div>
                <p>
                  <strong
                    >The energy used by a Symone loaded with 6 to 9 cars or motorbikes will be less than the energy needed for these same vehicles to travel alone on the motorway. </strong
                  >
                </p>
                <p>
                  In fact, a 44-tonne diesel HGV on the motorway does not consume as much energy as 30 individual diesel cars (30*1.5t). On average, a HGV consumes 'only' 6 times more than a car. 
                </p>
                <p>
                  That's because HGVs have a lower speed, you have to take into account the aerodynamics of all individual vehicles, the friction of more wheels on the ground, the weight distribution, the engine of heavy vehicles is more adapted, etc. 
                </p>
                <p>
                  However, even if the question of energy relevance with Symone does not arise, it is interesting to ask whether energy efficiency should take priority over CO2 reduction. Especially when you compare decarbonised energy (a Symone) with carbonised energy (6 to 9 cars/motorbikes). 
                </p>
                <p>
                  This will make it possible to avoid the CO2 emissions that these vehicles would have produced. That's the effect we're all looking for in the ecological and energy transition. 
                </p>
              </div>
            </el-collapse-item>

            <el-collapse-item
              title="Why does Symone only use one vehicle to transport the cars and their passengers?"
              name="12"
            >
              <div>
                <iframe
                  style="border: none; overflow: hidden"
                  src="https://www.facebook.com/plugins/video.php?height=306&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F1289180988692204%2F&amp;show_text=true&amp;width=560&amp;t=0"
                  data-opt-src="https://www.facebook.com/plugins/video.php?height=306&amp;href=https%3A%2F%2Fwww.facebook.com%2FSymoneMobility%2Fvideos%2F1289180988692204%2F&amp;show_text=true&amp;width=560&amp;t=0"
                  width="800"
                  height="750"
                  frameborder="0"
                  scrolling="no"
                  allowfullscreen="allowfullscreen"
                  data-opt-lazy-loaded="true"
                ></iframe>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div class="footer">
      <div style="margin: 50px">
        <div
          style="
            font-size: 20px;
            color: #e6007e;
            font-weight: 400;
            text-align: center;
          "
        >
          FOLLOW US
          <br />
          <a href="https://fr.linkedin.com/company/symone" target="_blank"
            ><i
              class="fab fa-linkedin"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <a href="https://twitter.com/SymoneMobility" target="_blank"
            ><i
              class="fab fa-twitter"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <a
            href="https://www.instagram.com/symonemobility/?hl=fr"
            target="_blank"
            ><i
              class="fab fa-instagram"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <a href="https://www.facebook.com/SymoneMobility/" target="_blank"
            ><i
              class="fab fa-facebook"
              style="color: #000047; font-size: 20px"
            ></i
          ></a>
          <br />
          <br />
          CONTACT US
          <br />
          <span style="color: #000047"
            >contact@symone.fr<br />+33 6 17 33 43 93</span
          >
          <br />
          <br />
          WRITE TO US
          <br />
          <a href="/contact" target="_blanc"
            ><span style="color: #000047">Contact</span></a
          >
        </div>
        <div
          style="
            font-size: 20px;
            color: #000047;
            font-weight: 400;
            text-align: center;
          "
        >
          <br />
          <a href="/en" target="_blank">Welcome on board</a>
          <br /><br />
          <a href="/moto" target="_blank">Symone by motorcycle</a>
          <br /><br />
          <a href="/en/#home-tag-2" target="_blank"
            >A new road experience</a
          >
          <br /><br />
          <a href="/condition" target="_blank">General Conditions of Sale</a>
          <br />
        </div>
      </div>
      <img src="../assets/images/ui/logo_couleur (1).png" alt="Logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqPageEN",
  components: {},
  data() {
    return {
	};
  },
  mounted(){
    this.$nextTick(() => {
      const elements = document.querySelectorAll('.el-collapse-item__header');
      elements.forEach(element => {
        element.style.lineHeight = 'normal';
        element.style.textAlign = 'left';
      });
    });
  }
};
</script>
<style lang="scss" scoped>
* {
  text-decoration: none;
}
.border {
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
.container {
  color: #000047;
  width: 100%;
}
.title {
  color: #000047;
  font-size: 50px;
  font-weight: 600;

  background-image: url("../assets/images/ui/aile-couleur-transparant.png");
  min-height: 250px;
  background-size: cover;
  background-position: bottom;
  width: 100%;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.menu-mobile{
  display: none;
}
.nav {
  padding-top: 25px;
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: start;
  overflow-x: scroll;
  position: fixed;
}
.nav::-webkit-scrollbar {
  display: none;
}
.nav::before {
  z-index: -1;
  content: ""; 
  position: absolute;
  top: 0; 
  left: 0;
  width: 100%;
  height: 100%; 
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    transparent
  );
}
.menu {
  display: flex;
}
.menu a {
  color: white;
  margin: 0 10px;
}
.details {
  color: #dddddd;
  font-size: 20px;
  font-weight: 300;
  text-transform: uppercase;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}
@media screen and (max-width: 512px){
  .menu-web{
    display: none;
  }
  .menu a{
    margin: 0 15px !important;
  }
  .menu-mobile{
    display: inline-block;
    border-radius: 30px;
    margin: -15px 15px 0 15px;
    width: 65px;
  }
}
.txt {
  max-width: 1000px;
  text-align: left;
  * {
    margin: revert;
  }
  img {
    max-width: 100%;
  }
}
.w-1 {
  width: 100%;
  text-align: -webkit-center;
}
.cover img {
  max-width: 60vw;
  max-height: 50vh;
}

.footer {
  height: 100vh;
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 100%;
  }
}
a {
  color: #e6007e;
}
</style>
